import React from 'react';
import {SyncLoader} from 'react-spinners';

function Message({ type = 'error', message = '' }) {
  if (message == '') {
    return null;
  }
  const runningMessage = () => (
    <div className="flex items-center mb-5">
      <div
        className="w-96 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-auto"
        role="alert"
      >
        <strong className="font-bold"><SyncLoader loading={true} color='lightBlue' /></strong>
        <span className="block sm:inline">{message.toString()}</span>
      </div>
    </div>
  );
  const errorMessage = () => (
    <div className="flex items-center mb-5">
      <div
        className="w-96 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-auto"
        role="alert"
      >
        <strong className="font-bold">ERROR!&nbsp;</strong>
        <span className="block sm:inline">{message.toString()}</span>
      </div>
    </div>
  );
  const successMessage = () => (
    <div className="flex items-center mb-5">
      <div
        className="w-96 bg-red-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mx-auto"
        role="alert"
      >
        <strong className="font-bold">Success!&nbsp;</strong>
        <span className="block sm:inline">{message}</span>
      </div>
    </div>
  );
  if(type === 'running') {
    return runningMessage();
  }
  return type === 'error' ? errorMessage() : successMessage();
}
export default Message;

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {toast } from 'react-toastify';
import IDO from '../IDO/IDO';
import Login from '../Login/Login';
import Register from '../Register/Register';
import Staking from '../Staking/Staking';
import Magic from '../Magic/Magic';
import IDVTStake from '../IDVTStaking/Staking';
import Assets from '../Assets/Assets';
import useToken from './useToken';
import useStorage from './useStorage';
import Private from '../Private/Private';
import Portfolio from '../Portfolio/Portfolio';
import ChangePassword from '../Account/ChangePassword';
import Profile from '../Account/Profile';
import useWeb3 from './useWeb3';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StakingProvider } from '../../context/StakingContext';
import { VastingProvider } from '../../context/IDOContext';
import { useEffect } from 'react';
import {io} from 'socket.io-client';
import { useState } from 'react';

function App() {
  const { account, setAccount } = useWeb3();
  const { setUser } = useStorage();
  const toastId = React.useRef(null);
  // useEffect(()=>{
  //   if(account){
      
  //     let  progressMessage = '';
  //     var socket = io.connect(process.env.REACT_APP_API_URL);
  //     socket.emit('join', {clientID: account});
  //     socket.on('connect', (data)=>{
  //       console.log('connected to socket');
  //     });
  //     socket.on('sync_progress', function(data){
  //       if (data.msg != progressMessage) {
  //         progressMessage = data.msg;
  //         toastId.current = toast(data.msg,{progress: data.progress});
          
  //       } else {
  //         toast.update(toastId.current,{progress : data.progress });
  //       }
  //     })
  //     socket.on('sync_update', function(data){
        
  //       if(data.type === 'success')
  //         toast.success(data.msg);
  //       else if(data.type === 'running')
  //         toast.info(data.msg);
  //       else 
  //         toast.error(data.msg);
  //     });
  //   }
  // },[account])
  return (
    <div className="wrapper">
      
        <BrowserRouter>
          <Routes>
            <Route
              path="/ido"
              element={account ?  <VastingProvider><IDO /></VastingProvider> : 
                <Login setAccount={setAccount} setUser= {setUser} />}
            />
            <Route path="/private" element={<Private />} />
            <Route
              path="/account/changepassword"
              element={account ? <ChangePassword /> : <Login setAccount={setAccount} setUser= {setUser} />}
            />
            <Route
              path="/account/profile"
              element={account ? <Profile /> : <Login setAccount={setAccount} setUser= {setUser} />}
            />
            <Route
              path="/defi"
              element={account ? <Staking /> : <Login setAccount={setAccount} setUser= {setUser} />}
            />
            <Route
              path="/idvtstake"
              element={account ?  <StakingProvider> <IDVTStake  /></StakingProvider>  : <Login setAccount={setAccount} setUser= {setUser} />}
            />
            <Route
              path="/"
              element={account ? <Magic /> : <Login setAccount={setAccount} setUser= {setUser} />}
            />
            <Route
              path="/portfolio"
              element={account ? <Portfolio /> : <Login setAccount={setAccount} setUser= {setUser} />}
            />
            {/* <Route path="/register" element={<Register   />} /> */}
            {/* <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} /> */}
            <Route
              path="/assets"
              element={account ? <Assets /> : <Login setAccount={setAccount} setUser= {setUser}/>}
            />
          </Routes>          
        </BrowserRouter>
        <ToastContainer  newestOnTop={true} autoClose= {true}/>
      {/* </Web3Context.Provider> */}
    </div>
  );
}

export default App;

import { useState } from 'react';

export default function useNetwork() {
  const getNetwork = () => {
    const network = sessionStorage.getItem('network');
    return network == null ? 'bsc' : network;
  };

  const [network, setNetwork] = useState(getNetwork());

  const saveNetwork = (network) => {
    sessionStorage.setItem('network', network);
    setNetwork(network);
  };

  return {
    setNetwork: saveNetwork,
    network
  };
}

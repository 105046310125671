import { Button } from '@material-tailwind/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { constructURLForRoute, REGISTER_URL } from '../../urls';

function Register({ setToken, setUser }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [token, setToken] = useState(sessionStorage.getItem('token'));
  const [error, setError] = useState({});
  async function registerUser(userobj) {
    return fetch(constructURLForRoute(REGISTER_URL), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userobj)
    }).then((data) => data.json());
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const email = e.target.elements.email?.value;
    const password = e.target.elements.password?.value;
    const name = e.target.elements.name?.value;
    const user = await registerUser({ email, password, name });
    if (user.code) {
      setError(user);
    } else {
      setError({});
      setUser(user);
      setToken(user.tokens.access);
      setTimeout(() => {
        navigate('/');
      }, 1000);
    }
  };
  return (
    <div className="h-screen flex bg-blue-700">
      <div className="w-full max-w-lg m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-16">
        <h1 className="text-2xl font-medium text-primary mt-4 mb-12 text-center">
          Create your account 🔐
        </h1>

        <form onSubmit={handleFormSubmit}>
          <div>
            <label htmlFor="name">Name</label>
            <input
              type="name"
              className="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
              id="name"
              placeholder="Your Name"
            />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
              id="email"
              placeholder="Your Email"
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
              id="password"
              placeholder="Your Password"
            />
          </div>
          {error.message && (
            <div className="flex items-center mb-5">
              <div
                className="w-96 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-auto"
                role="alert"
              >
                <strong className="font-bold">ERROR!&nbsp;</strong>
                <span className="block sm:inline">{error.message}</span>
              </div>
            </div>
          )}
          <div className="flex  items-center mt-6">
            <Button type="submit" className="w-full" size="lg" color="lightBlue" ripple="light">
              {loading ? 'Processing' : 'Register'}
            </Button>
          </div>
          <div className="flex items-center mt-6">
            <span>
              Existing Users?{' '}
              <button
                className="text-blue-500 items-right background-transparent uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                onClick={() => {
                  navigate('/');
                }}
                type="button"
              >
                <i className="fas fa-heart" /> Click here to login!
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;

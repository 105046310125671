import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo.png';
import useStorage from './App/useStorage';
import useToken from './App/useToken';
import useNetwork from './App/useNetwork';
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input
} from '@material-tailwind/react';
import useWeb3 from './App/useWeb3';
import { io } from 'socket.io-client';
import { AiOutlineArrowDown, AiOutlineCaretDown, AiOutlineDown } from 'react-icons/ai';

function Stack({ children, direction, spacing }) {
  const stack = React.Children.toArray(children);
  const directionClass = direction ? `flex-${direction}` : '';
  const spacingClass = spacing ? `gap-${spacing}` : '';
  return (
    <div className={`flex flex-wrap ${directionClass} ${spacingClass}`}>
      {stack.map((child, i) => (
        <div key={i} className="flex-3 m-2">
          {child}
        </div>
      ))}
    </div>
  );
}
function Header({ active, onSyncClick, syncButton }) {
  const { setToken, token } = useToken();
  const { setNetwork, network } = useNetwork();
  const [visibleProfile, setVisibleProfile] = useState(false);
  const { account, setAccount } = useWeb3();
  const {
    user: { wallet, id, countributingWallet } = {},
    logOutUser,
    setUser,
    user
  } = useStorage();

  const pages = [
    // {
    //   name: 'Portfolio',
    //   link: '/'
    // },
    { name: 'IDO', link: '/ido' },
    {
      name: 'DeFi',
      link: '/defi'
    },
    // {
    //   name: 'Magic',
    //   link: '/magic'
    // },
    // {
    //   name: 'Assets',
    //   link: '/assets'
    // },
    { name: 'Private', link: '#' },
    {
      name: "Stake IDT",
      link: '/idvtstake'
    },
    {
      name: 'Chain - ' + network.toUpperCase(),
      items: [
        {
          name: 'BSC',
          onClick: () => {
            setNetwork('bsc');
            window.location.reload();
          }
        },
        {
          name: 'Ethereum',
          onClick: () => {
            setNetwork('eth');
            window.location.reload();
          }
        },
        {
          name: 'Polygon',
          onClick: () => {
            setNetwork('poly');
            window.location.reload();
          }
        },
        {
          name: 'Velas',
          onClick: () => {
            setNetwork('velas');
            window.location.reload();
          }
        }
      ],
      link: '#'
    },
    {
      name: countributingWallet ? `${countributingWallet.substring(0, 6)}...${countributingWallet.substring(countributingWallet.length - 6)}` : '',
      link: '#',
      items: [
        { name: 'Profile', enable: visibleProfile, link: '/account/profile' },
        { name: 'Assets', link: '/assets' },
        { name: 'Portfolio', link: '/portfolio' },
        // { name: 'Change Password', link: '/account/changepassword' },
        {
          name: 'Disconnect',
          link: '#',
          onClick: () => {
            logOutUser(token)
              .then(() => { })
              .catch(() => { })
              .finally(() => {
                setAccount("");
                setUser({});
                window.location.reload();
              });
          }
        }
      ],
      additionalMenu: {
        name: 'Wallet Test',
        items: [
          { name: 'one', link: '#' },
          { name: 'tow', link: '#' }
        ]
      }
    }
  ];
  const [,] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openMenu, setMenuOpen] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [openWallet, setOpenWallet] = useState(false);
  const [newWallet, setNewWallet] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [showProgress, setShowProgress] = useState(false)
  const [syncUpdate, setSyncUpdate] = useState({ msg: '', complete: 0, total: 5, dt: new Date() });
  const [currentProgress, setCurrentProgress] = useState(0);
  const [socket, setSocket] = useState(io.connect(process.env.REACT_APP_API_URL));
  const [syncing, setSyncing] = useState(false);
  let hndComplete = useRef();
  useEffect(() => {
    if (account != '') {
      loadProfile(account);
      socket.emit('join', { clientID: account });
      socket.on('sync_update', function (data) {
        setSyncUpdate(data);
      });
    }
  }, [account]);
  useEffect(() => {
    if (socket) {

      socket.on('sync_progress', function (data) {
        const complete = hndComplete.current.value;

        if (data.progress > 0) {
          const p = ((complete - 1) * 20) + (data.progress * 100 * 0.2)
          // console.log(data.msg, complete,data.progress, p )
          if (p < 100) setShowProgress(true)
          setCurrentProgress(Math.round(p));
          if (p < 100) {
            setSyncing(true);
            setShowProgress(true);
          } else {
            setSyncing(false);
          }
        }
      })
    }
  }, [syncUpdate, socket]);
  const loadProfile = async (account) => {
    setLoading(true);
    // eslint-disable-next-line no-undef
    fetch(`${process.env.REACT_APP_API_URL}/v1/users/${id}`, {
      method: 'GET',
      headers: {
        wallet: account,
        'Content-Type': 'application/json'
      }
    })
      .then((r) => r.json())
      .then((data) => {
        setLoading(false);
        setUserInfo(data);
        if (data.profile && data.profile.email)
          setVisibleProfile(true);

      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const activateWallet = (wallet) => {
    setOpenWallet(false);
    // eslint-disable-next-line no-undef
    fetch(`${process.env.REACT_APP_API_URL}/v1/wallets/${id}`, {
      method: 'PATCH',
      headers: {
        wallet: account,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        wallet
      })
    })
      .then((r) => r.json())
      .then((data) => {
        if (data.code) {
          alert(data.message);
        } else {
          const userUpdate = Object.assign({}, user);
          userUpdate.wallet = wallet;
          setUser(userUpdate);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  const handleSubmit = () => {
    // eslint-disable-next-line no-undef
    fetch(`${process.env.REACT_APP_API_URL}/v1/wallets/${user.id}`, {
      method: 'POST',
      headers: {
        wallet: account,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        wallet: newWallet
      })
    })
      .then((r) => r.json())
      .then((data) => {
        if (data.code) {
          setErrorMsg(data);
        } else {
          setUserInfo(data);
          setOpen(false);
        }
      })
      .catch((err) => {
        setErrorMsg(err.message);
      });
  };
  const handleOpen = () => {
    setErrorMsg({});
  };
  const handleClose = () => {
    setErrorMsg({});
    setOpen(false);
  };
  return (
    <header>
      <nav className="shadow-lg px-2 sm:px-4 py-2 rounded bg-white text-black">
        {/* Logo and Tabs */}
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <Link to={'/'} className="flex items-center">
            <img src={logo} className="mr-3 h-12" alt="Coin Vesting" />
            <span className="self-center whitespace-nowrap text-[#233876]">
              Coin Vesting
            </span>
          </Link>
          <input type="hidden" ref={hndComplete} value={syncUpdate.complete} />
          <button
            data-collapse-toggle="mobile-menu"
            onClick={() => {
              setMobileMenuOpen(!isMobileMenuOpen);
            }}
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-700 focus:ring-gray-600"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
            <svg
              className="hidden w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div
            className={`w-full md:block md:w-auto ${isMobileMenuOpen ? '' : 'hidden'}`}
            id="mobile-menu"
          >
            <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
              {pages.filter(d => d.enable === undefined || d.enable === true).map((page, index) => (
                <li
                  key={page.name}
                  style={active === page.link ? { backgroundColor: 'rgba(255,255,255,.2)' } : {}}
                  className="ml-0 relative rounded-lg py-3 my-0 px-3"
                >
                  {page.items == undefined ? (
                    <Link
                      to={page.link}
                      className="block text-lg py-2  border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-900 md:p-0 text-blue-900 md:hover:text-dark hover:bg-gray-700 hover:text-dark md:hover:bg-transparent border-gray-700 text-blue"
                    >
                      {page.name}{' '}
                    </Link>
                  ) : (
                    <a
                      onClick={() => {
                        setMenuOpen({
                          ...openMenu,
                          [index]: !openMenu[index]
                        });
                      }}
                      href="#"
                      data-bs-toggle="dropdown"
                      type="button"
                      id={page.name}
                      className="block text-lg dropdown-toggle  py-2 pr-4 pl-3 text-blue border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-900 md:p-0 text-blue-900 md:hover:text-dark hover:bg-gray-700 hover:text-dark md:hover:bg-transparent border-gray-700"
                    >
                      <span className="flex text-lg items-center">
                        {' '}
                        {page.name}{' '}
                        {page.items && (
                          <svg
                            className="w-4 h-4 text-lg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </span>{' '}
                    </a>
                  )}

                  {page.items && (
                    <ul
                      aria-labelledby={page.name}
                      className={`dropdown-menu origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${openMenu[index] === true ? '' : 'hidden'
                        }`}
                      role="menu"
                      aria-orientation="vertical"
                      style={{ zIndex: 10 }}
                    >
                      {page.items.filter(d => d.enable === undefined || d.enable === true).map((item) => (
                        <li key={item.name}>
                          <a
                            href={item.link}
                            onClick={() => {
                              setMenuOpen({
                                ...openMenu,
                                [index]: !openMenu[index]
                              });
                              if (item.onClick) item.onClick();
                            }}
                            className="text-lg block px-4 py-2 text-sm text-gray-700 md:hover:bg-blue-700 md:hover:text-blue"
                            role="menuitem"
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
      {/* Choose Wallet */}
      <div className="container-fluid flex flex-wrap justify-center items-center mx-auto pt-5">
        <div className="w-full">
          <div className="relative inline-block text-left float-right mr-5" style={{ zIndex: 5 }}>
            <div className="flex flex-row">
              <div className="container mx-auto flex justify-center">
                <div className="flex items-center">


                </div>
              </div>
              {syncButton === true &&
                <button disabled={syncing}
                  className="py-2 px-5 mx-1 rounded-md bg-white text-blue-700  font-bold"
                  onClick={(e) => {
                    e.preventDefault();
                    setSyncUpdate({ msg: '', complete: 0, total: 5, dt: new Date() });
                    setShowProgress(true);
                    if (onSyncClick) {
                      onSyncClick();
                    }
                  }}
                >
                  {syncing ? 'Syncing...' : 'Sync'}
                </button>
              }
              <button
                type="button"
                className="inline-flex justify-center md:w-full  rounded-md text-blue-700 border border-gray-300 shadow-sm px-2 py-2 bg-white text-sm font-bold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                id="menu-button"
                onClick={() => {
                  setOpenWallet(!openWallet);
                }}
                aria-expanded="true"
                aria-haspopup="true"
              >
                {wallet ? `${wallet.substring(0, 6)}...${wallet.substring(wallet.length - 6)}` : ''}
                <AiOutlineCaretDown size={20} />
              </button>
            </div>

            <div
              className={
                ' origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ' +
                (openWallet ? '' : 'hidden')
              }
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex="-1"
            >
              <div className="py-1" role="none">
                <a
                  href="#"
                  className="text-blue-700 block px-4 py-2 w-34 text-sm"
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-0"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Add New Wallet
                </a>
                {userInfo.wallets &&
                  userInfo.wallets.map((u, i) => {
                    return (
                      <a
                        href="#"
                        className="text-gray-700 block px-4 py-2 w-34 text-sm"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-0"
                        onClick={() => {
                          activateWallet(u.wallet);
                        }}
                      >
                        {`${u.wallet.substring(0, 8)}...${u.wallet.substring(
                          u.wallet.length - 12
                        )}`}
                      </a>
                    );
                  })}
              </div>
            </div>

          </div>


          <Dialog open={open} handler={handleOpen}>
            <DialogHeader>Add New Wallet</DialogHeader>
            <DialogBody divider>
              <Stack direction="row">
                <Input
                  type={'text'}
                  value={newWallet}
                  label="Wallet  Address"
                  className="w-96"
                  variant="outlined"
                  onChange={(e) => {
                    setNewWallet(e.target.value);
                  }}
                />
                {errorMsg.message && (
                  <div className="flex items-center mb-5">
                    <div
                      className="w-full bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-auto"
                      role="alert"
                    >
                      <strong className="font-bold">ERROR!&nbsp;</strong>
                      <span className="block sm:inline">{errorMsg.message}</span>
                    </div>
                  </div>
                )}
              </Stack>
            </DialogBody>
            <DialogFooter>
              <Button variant="text" color="red" onClick={handleClose} className="mr-1">
                <span>Cancel</span>
              </Button>
              <Button variant="gradient" color="green" onClick={handleSubmit}>
                <span>Confirm</span>
              </Button>
            </DialogFooter>
          </Dialog>
          {/* <Stack direction={'row'} spacing={2}>
              {userInfo.wallets &&
                userInfo.wallets.map((u, i) => {
                  return (
                    <Chip
                      dismissible={{
                        onClose: (e) => {
                          e.preventDefault();
                          deleteWallet(u.wallet);
                        }
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (isDelete === false) {
                          activateWallet(u.wallet);
                        }
                      }}
                      color="gray"
                      value={u.wallet}
                    />
                  );
                })}
            </Stack> */}
        </div>

        <div className="w-1/2">
          {(showProgress) &&
            <>
              {(syncUpdate.msg) &&
                <div className="flex" >
                  <div className="flex-1 rounded-md bg-white mt-4 py-4 px-2 mb-20" style={{ marginBottom: '20px' }}>
                    <div className='text-center mx-auto'>{syncUpdate.msg}
                      <button onClick={() => {
                        setShowProgress(false);
                      }} className="bg-transparent text-xs hover:bg-red-500 text-red-700 font-semibold hover:text-blue py-1 px-2 border border-red-500 hover:border-transparent rounded float-right">
                        X
                      </button>
                    </div>
                    <div className='progressbar w-full rounded-full bg-orange-200 h-4 mt-2'>
                      <div className={'progress bg-green-600 progress-bar-stripes text-xs font-medium text-blue-100 text-center p-0.5 leading-none' + (currentProgress < 100 ? ' progress-bar-animated' : '')} style={{ width: `${currentProgress}%` }}>{currentProgress + '%'}
                      </div>
                    </div>
                    <div className='text-center mx-auto'>Step {syncUpdate.complete} of {syncUpdate.total}</div>
                  </div>
                </div>
              }
            </>
          }
        </div>
      </div>
    </header>
  );
}
export default Header;

import { Button } from '@material-tailwind/react';
import React, { useState, useContext } from 'react';
import {io} from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { constructURLForRoute, LOGIN_URL, VALIDATE_USER } from '../../urls';
import useWeb3 from '../App/useWeb3';



function Login({ setAccount, setUser }) {
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const {getAccount, account} = useWeb3();
  // const [token, setToken] = useState(sessionStorage.getItem('token'));
  async function loginUser(account) {
    setLoading(true)
    if(account ==="" || account === undefined)
      return;
    return fetch(constructURLForRoute(VALIDATE_USER), {
      method: 'GET',
      headers: {
        wallet: account,
        'Content-Type': 'application/json'
      },
     // body: JSON.stringify(credentials)
    }).then((data) => data.json()).catch(err=>{
      setError(error);
      alert(error.message);
    });
  }
  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();
  //   const email = e.target.elements.email?.value;
  //   const password = e.target.elements.password?.value;
  //   const user = await loginUser({ email, password });
  //   if (user.code) {
  //     setError(user);
  //   } else {
  //     setError({});
  //     setUser(user);
  //     setToken(user.tokens.access);
  //   }
  // };
  const connectWallet = async() =>{
    const account = await getAccount();
    const user = await loginUser(account);
    if(user && user.code) {
      setError(user);
      alert(user.message);
    }
    if(user != undefined && user.wallet != undefined)
    {
      setUser(user);
      setAccount(account);
    }
    setLoading(false);
      
    //var socket = io.connect(process.env.REACT_APP_API_URL);
    // socket.emit('join', {clientID: account});
    
  }
  return (
    <div className="h-screen flex bg-blue-700">
      <div className="w-full max-w-lg m-auto  rounded-lg  shadow-default py-10 px-16">
        {/* <h1 className="text-2xl font-medium text-primary mt-4 mb-12 text-center">
          Connect to your Wallet 🔐
        </h1> */}
        <Button type="submit" size="lg" onClick={connectWallet}  className="w-full " color="white"  ripple="dark">
             {loading=== true? 'Processing...': 'Connect Wallet'}
            </Button>
        {/* <form onSubmit={handleFormSubmit}>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
              id="email"
              placeholder="Your Email"
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
              id="password"
              placeholder="Your Password"
            />
          </div>
          {error.message && (
            <div className="flex items-center mb-5">
              <div
                className="w-96 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-auto"
                role="alert"
              >
                <strong className="font-bold">ERROR!&nbsp;</strong>
                <span className="block sm:inline">{error.message}</span>
              </div>
            </div>
          )}
          <div className="flex items-center mt-6">
            <Button type="submit" size="lg" className="w-1/3" color="lightBlue" ripple="light">
              Login
            </Button>
            <div style={{ paddingLeft: '50px', float: 'right' }}>
              {' '}
              New User?{' '}
              <button
                className="text-blue-500 items-right background-transparent uppercase py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                onClick={() => {
                  navigate('/register');
                }}
                type="button"
              >
                {' '}
                Click here!
              </button>
            </div>
          </div>
          <div className="flex items-center mt-6">
            <span>
              Forgot Password?{' '}
              <button
                className="text-blue-500 items-right background-transparent uppercase py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                onClick={() => {
                  navigate('/forgot-password');
                }}
                type="button"
              >
                Click Here to recover
              </button>
            </span>
          </div>
        </form> */}
      </div>
    </div>
  );
}

export default Login;

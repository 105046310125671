import React, { useEffect, useState } from "react";
import { ethers, utils } from "ethers";
import {toast } from 'react-toastify';
import { stakingABI, stakingtAddress, tokenAddress, tokenABI } from "../utils/constants";

export const StakingContext = React.createContext();

const { ethereum } = window;
const createEthereumContract = (address, abi) => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  let signer = provider.getSigner();
  const transactionsContract = new ethers.Contract(address || tokenAddress, abi || tokenABI, signer);
  return transactionsContract;
};

export const StakingProvider = ({ children }) => {
  const [formData, setformData] = useState({ addressTo: "", amount: "", withdrawAmount: "" });
  const [currentAccount, setCurrentAccount] = useState("");
  const [balance, setBalance] = useState(0);
  const [stakeAmount, setStakeAmount] = useState(0);
  const [rewardAmount, setRewardAmount] = useState(0);
  const [earnedAmount, setEarnedAmount] = useState(0);
  const [allowance, setAllowence] = useState(0);
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [stakingDone, setStakingDone] = useState(false);
  const [lockupPeriod, setLockupPeriod] = useState(0);
  const [actionNotification , setActionNotification] = useState({message: '',title:'', messageType: '',origin: 'withdraw'});

  const handleChange = (e, name) => {
    if(e.target.max && e.target.value > parseFloat(e.target.max) ) return;
    setformData((prevState) => ({ ...prevState, [name]: e.target.value }));
  };
  const fetchBalance = async (address) => {
    const transactionsContract = createEthereumContract(tokenAddress, tokenABI);
    const bal = await transactionsContract.balanceOf(address);
    setBalance(utils.formatEther(bal, { decimals: 18 }));
  };
  const getTokenSymbol = async (address) => {
    const transactionsContract = createEthereumContract(tokenAddress, tokenABI);
    console.log(transactionsContract);
    const bal = await transactionsContract.symbol();
    setTokenSymbol(bal);
  };
  const getStakeBalance = async (address) => {
    const transactionsContract = createEthereumContract(stakingtAddress, stakingABI);
    const bal = await transactionsContract.amountStaked(address);
    setStakeAmount(parseFloat(utils.formatEther(bal, { decimals: 18 })).toFixed(4));
  };
  const getLockupPeriod = async (address) => {
    const transactionsContract = createEthereumContract(stakingtAddress, stakingABI);
    const bal = await transactionsContract.userLockupPeriod(currentAccount);
    setLockupPeriod(bal);
  };
  const getRewards = async (address) => {
    const transactionsContract = createEthereumContract(stakingtAddress, stakingABI);
    const bal = await transactionsContract.rewardOf(address);
    console.log("rewards", utils.formatEther(bal, { decimals: 18 }));
    setRewardAmount(parseFloat(utils.formatEther(bal, { decimals: 18 })).toFixed(4));
  };
  
  
  const approveTransaction = async () => {
    try {
      setActionNotification({ ...actionNotification, origin: 'approve', message: '' });
      setIsLoading(true);
      const transactionsContract = createEthereumContract(tokenAddress, tokenABI);
      const numberOfTokens = utils.parseUnits(formData.amount.toString(), 18);
      const tx = await transactionsContract.approve(stakingtAddress, numberOfTokens);
      await tx.wait();
      setIsLoading(false);
      await getApprovedAllowence();
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }

  };

  const claimRewards = async () => {
    try {
      setActionNotification({...actionNotification, origin: 'claim',message: ''});
      setIsLoading(true);
      const transactionsContract = createEthereumContract(stakingtAddress, stakingABI);
      const tx = await transactionsContract.claimRewards();
      await tx.wait();
      setIsLoading(false);
      await getRewards(currentAccount);
      toast.success('Successfully claimed rewards', 'Success', 3000);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message, 'Error', 3000);
    }
    
  };

  const withdraw = async () => {
    try {

      if(lockupPeriod > (new Date().getTime() / 1000)){
        toast.error(`You can not withdraw until lockup period is over. Lockup period will end on ${new Date(lockupPeriod * 1000)}`, 'Error', 3000);
        return;
      }
      setActionNotification({...actionNotification, origin: 'withdraw',message: ''});
      setIsLoading(true);
      const transactionsContract = createEthereumContract(stakingtAddress, stakingABI);
      const numberOfTokens = utils.parseUnits(formData.withdrawAmount.toString(), 18);
      const tx = await transactionsContract.withdraw(numberOfTokens);
      await tx.wait();
      setIsLoading(false);
      await getStakeBalance(currentAccount);
      toast.success('Successfully withdrew tokens', 'Success', 3000);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message, 'Error', 3000);
    }
    
  };
  
  const withdrawForce = async () => {
    try {
      setActionNotification({...actionNotification, origin: 'withdraw',message: ''});
      setIsLoading(true);
      const transactionsContract = createEthereumContract(stakingtAddress, stakingABI);
      const numberOfTokens = utils.parseUnits(formData.withdrawAmount.toString(), 18);
      const tx = await transactionsContract.withdrawForce(numberOfTokens);
      await tx.wait();
      setIsLoading(false);
      await getStakeBalance(currentAccount);
      toast.success('Successfully withdrew tokens', 'Success', 3000);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message, 'Error', 3000);
    }
    
  };
  

  const checkIfWalletIsConnect = async () => {
    try {
      if (!ethereum) return alert("Please install MetaMask.");
      const accounts = await ethereum.request({ method: "eth_accounts" });
      if (accounts.length) {
        setCurrentAccount(accounts[0]);
      } else {
        console.log("No accounts found");
      }
      ethereum.on("accountsChanged", async () => {
        checkIfWalletIsConnect();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const connectWallet = async () => {
    try {
      if (!ethereum) return alert("Please install MetaMask.");

      const accounts = await ethereum.request({ method: "eth_requestAccounts", });
      setCurrentAccount(accounts[0]);
      //  window.location.reload();
    } catch (error) {
      console.log(error);

      throw new Error("No ethereum object");
    }
  };
  const getApprovedAllowence = async () => {
    const transactionsContract = createEthereumContract(tokenAddress, tokenABI);
    const allowance = await transactionsContract.allowance(currentAccount, stakingtAddress);
    setAllowence(utils.formatEther(allowance, { decimals: 18 }));
    return allowance;
  }

  const stakeToken = async () => {
    try {
    setStakingDone(false);
    setActionNotification({...actionNotification, origin: 'stake'});
    const numberOfTokens = utils.parseUnits(formData.amount.toString(), 18);
    const numberOfAllowence = utils.parseUnits(allowance, 18);
    if( numberOfAllowence < numberOfTokens){
      toast.error('You do not have enough tokens to stake', 'Error', 3000);
        return;        
    }
    const contract = createEthereumContract(stakingtAddress, stakingABI);
    contract.provider.getGasPrice().then((currentGasPrice) => {
      const gasPrice = utils.hexlify(currentGasPrice);
      console.log(`gas_price: ${gasPrice}`);

      if (formData.amount>0) {
        console.log(`numberOfTokens: ${numberOfTokens}`);
        contract.deposit(numberOfTokens, { gasPrice }).then(async (tx) => {
            setIsLoading(true);
            await tx.wait();
            setIsLoading(false);
            setStakingDone(true);
        }).catch((err)=>{
            console.log(err);
            if(err.data)
              toast.error(err.data.message, 'Error', 3000);
            else
              toast.error(err.message, 'Error', 3000);
        });
      }
    }).catch((err) => {
        console.log(err);
        if(err.data)
              toast.error(err.data.message, 'Error', 3000);
            else
              toast.error(err.message, 'Error', 3000);
    });
    
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message, 'Error', 3000);
    }
        
    
    
  };

  useEffect(() => {
    checkIfWalletIsConnect();
    
  }, []);

  useEffect(()=>{
    if(currentAccount != "") {
      setInterval(() => {
        getRewards(currentAccount);
      }, 30000);
    }
  },[currentAccount])

  useEffect(() => {
    if (currentAccount !== ""  || stakingDone   === true) {
      fetchBalance(currentAccount);
      getStakeBalance(currentAccount);
      getTokenSymbol();
      
      getApprovedAllowence();
      
      getRewards(currentAccount);
      getLockupPeriod();
    }
  }, [currentAccount, stakingDone]);

  return (
    <StakingContext.Provider
      value={{
        connectWallet,
        currentAccount,
        balance,
        isLoading,
        handleChange,
        formData,
        stakeToken,
        fetchBalance,
        stakeAmount,
        tokenSymbol,
        rewardAmount,
        earnedAmount,
        allowance,
        withdraw,
        claimRewards,
        approveTransaction,
        actionNotification,
        lockupPeriod,
        withdrawForce,
      }}
    >
      {children}
    </StakingContext.Provider>
  );
};

import React, { useEffect } from 'react';
import Header from '../Header';
import useToken from '../App/useToken';
import useNetwork from '../App/useNetwork';

import 'react-loading-skeleton/dist/skeleton.css';
// import "@material-tailwind/react/tailwind.css";
import useStorage from '../App/useStorage';
import {
  constructURLForNetwork,
  FETCH_ASSETS_URL,
  HIDE_ASSET_URL,
  SYNC_ASSETS_URL
} from '../../urls';
import useWeb3 from '../App/useWeb3';

function Assets() {
  const { user, setUser } = useStorage();
  const { network } = useNetwork();
  const {account} = useWeb3();

  const [AllData, setAllData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [syncing, setSyncing] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState({});
  const [showHidden, setShowHidden] = React.useState(true);

  useEffect(() => {
    if (account != null ) {
      getAssets();
    }
  }, [account]);

  const getAssets = () => {
    setLoading(true);
    fetch(constructURLForNetwork(network, FETCH_ASSETS_URL), {
      method: 'POST',
      headers: {
        wallet: account
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.code) {
          setErrorMsg(data);
        } else {
          setAllData(data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
    // });
  };

  const syncAssets = () => {
    setSyncing(true);
    fetch(constructURLForNetwork(network, SYNC_ASSETS_URL), {
      method: 'POST',
      headers: {
        wallet: account,
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setSyncing(false);
        if (data.code) {
          setErrorMsg(data);
        } else {
          getAssets();
          alert('Sync Successfully');
          setErrorMsg({});
        }
      })
      .catch(() => {
        setSyncing(false);
      });
  };

  const toggleHidden = (contractAddress, hide) => {
    fetch(constructURLForNetwork(network, HIDE_ASSET_URL), {
      method: 'POST',
      headers: {
        wallet: account,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        hidden: hide,
        contractAddress
      })
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code) {
          setErrorMsg(data);
        } else {
          AllData.find((d) => d.contractAddress === contractAddress).hidden = !AllData.find(
            (d) => d.contractAddress === contractAddress
          ).hidden;
          setAllData([...AllData]);
        }
      });
  };

  const renderAssets = () => {
    if (AllData.length === 0 || AllData.length === undefined) {
      return null;
    }

    const aggData = AllData.filter((p) => p != null && p.price > 0).sort(
      (a, b) => b.price - a.price
    );
    return (
      <ul className="bg-white p-10 pt-2 mx-auto rounded-lg">
        <div className="inline-flex rounded-md shadow-sm float-right" role="group">
          <label id="chkShowHide">
            <input
              type="checkbox"
              onChange={() => {
                setShowHidden(!showHidden);
              }}
              htmlFor="chkShowHide"
            />{' '}
            Show Hidden
          </label>
        </div>
        <div className="inline-flex pt-3 w-full">&nbsp;</div>
        {aggData.map((p) => {
          if (showHidden && p.hidden === true) return;
          return (
            <li className="flex items-center mb-5 border-b-900 max-auto c-hand">
              {/* Coin Logo */}
              {/* <img
                src={p.project.logo}
                className="w-16 rounded-full"
                alt={p.project.projectTokenSymbol}
              /> */}

              <div className=" mx-5">
                {/* Coin Symbol */}
                <h3 className="text-blue-900">
                  <a
                    target="_blank"
                    href={`https://www.coingecko.com/en/coins/${p.coingeckoId}`}
                    rel="noreferrer"
                  >
                    {p.tokenName}
                    &nbsp;&nbsp;
                  </a>
                  <button
                    size="sm"
                    onClick={() => {
                      toggleHidden(p.contractAddress, !p.hidden);
                    }}
                    className="float-right hover:text-blue-500 text-blue py-1/2 px-2 "
                  >
                    {p.hidden === true ? 'Show' : 'Hide'}
                  </button>
                </h3>
                <p>
                  <button disabled className="h-10 w-64 sm:w-64 text-xs my-2 mx-1 px-1 text-blue-100 transition-colors duration-150 bg-green-700 rounded-lg focus:shadow-outline">
                    <span className="mr-2">Total</span>
                    <span className="inline-flex float-right px-2 py-1 text-xs font-bold leading-none text-blue-100 rounded-full ">
                      {`$${p.price}`}
                    </span>
                  </button>
                  <button disabled className="h-10 w-64 sm:w-64 text-xs my-2 mx-1 px-1 text-blue-100 transition-colors duration-150 bg-green-700 rounded-lg focus:shadow-outline">
                    <span className="mr-2">Tokens</span>
                    <span className="inline-flex float-right px-2 py-1 text-xs font-bold leading-none text-blue-100 rounded-full ">
                      {p.token}
                    </span>
                  </button>
                  <button disabled className="h-10 w-64 sm:w-64 text-xs my-2 mx-1 px-1 text-blue-100 transition-colors duration-150 bg-green-700 rounded-lg focus:shadow-outline">
                    <span className="mr-2">Price</span>
                    <span className="inline-flex float-right px-2 py-1 text-xs font-bold leading-none text-blue-100 rounded-full">
                      {`$${p.usd}`}
                    </span>
                  </button>
                </p>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  const SyncButton = (<div className="container mx-auto flex justify-center ">
    <div className="flex items-center ">
      <button
        className="py-2 px-5 mx-2 rounded-md bg-white text-blue-700  font-bold"
        onClick={(e) => {
          e.preventDefault();
          syncAssets();
        }}
      >
        {syncing ? 'Syncing...' : 'Sync'}
      </button>
    </div>
  </div>);
  return (
    <div className=" v-screen flex justify-center items-center">
      <div className="container mx-auto min-w-full min-h-screen bg-blue-600">
        <Header syncButton={SyncButton} active="/assets" />
        {errorMsg.message && (
      <div className="flex items-center mb-5 mt-5">
        <div
          className="w-192 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-auto"
          role="alert"
        >
          <strong className="font-bold">ERROR!&nbsp;</strong>
          <span className="block sm:inline">{errorMsg.message}</span>
        </div>
      </div>
    )}
        <div className="container mx-auto flex my-10">{renderAssets()}</div>
      </div>
    </div>
  );
}

export default Assets;

import React, { useEffect, useState } from "react";
import { ethers, utils } from "ethers";
import {toast } from 'react-toastify';
import { vastingABI } from "../utils/constants";

export const IDOContext = React.createContext();

const { ethereum } = window;
const createEthereumContract = (address, abi) => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  let signer = provider.getSigner();
  console.log(vastingABI);
  const transactionsContract = new ethers.Contract(address, abi || vastingABI, signer);
  return transactionsContract;
};

export const VastingProvider = ({ children }) => {
  const [formData, setformData] = useState({ addressTo: "", amount: "", withdrawAmount: "" });
  const [currentAccount, setCurrentAccount] = useState("");
  const [balance, setBalance] = useState(0);
  const [stakeAmount, setStakeAmount] = useState(0);
  const [rewardAmount, setRewardAmount] = useState(0);
  const [earnedAmount, setEarnedAmount] = useState(0);
  const [allowance, setAllowence] = useState(0);
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [stakingDone, setStakingDone] = useState(false);
  const [lockupPeriod, setLockupPeriod] = useState(0);
  const [actionNotification , setActionNotification] = useState({message: '',title:'', messageType: '',origin: 'withdraw'});

  const handleChange = (e, name) => {
    if(e.target.max && e.target.value > parseFloat(e.target.max) ) return;
    setformData((prevState) => ({ ...prevState, [name]: e.target.value }));
  };
  
  const claimAmount = async (contractAddress, address,idx) => {
    try {
      setActionNotification({ ...actionNotification, origin: 'approve', message: '' });
      setIsLoading(true);
      const transactionsContract = createEthereumContract(contractAddress);
      //console.log(allocationInfo);
      // const viewContract = createEthereumContract(contractAddress,commonABI);
      // const a = await viewContract.infoAllocations();
      // a.forEach((element,i) => {
      //   console.log('INDEX ', i);
      //   a[4].forEach(item=>{
      //     console.log(item.toString())
      // //   })
      //  });
      // console.log(a);
      const tx = await transactionsContract.claim(idx);
      await tx.wait();
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }

  };
  return (
    <IDOContext.Provider
      value={{
        isLoading,
        handleChange,
        claimAmount
      }}
    >
      {children}
    </IDOContext.Provider>
  );
};

import { useEffect, useState } from "react";

const Message = ({ title,message,messageType ="error" }) => {
    const [showMessage, setShowMessage] = useState(false);
    const [messageTitle, setMessageTitle] = useState(title);
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    useEffect(() => {
        if(message != ""){
            setShowMessage(true);
        }
        if(title == ""){
          setMessageTitle(capitalizeFirstLetter(messageType));
        }
        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
    },[message, messageType])
    if(!showMessage)
        return null;
    if(messageType === "error"){
        return <div className={`p-2 m-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800`} role="alert">
        <span className="font-medium">{messageTitle}!</span> {message}
      </div>
    } else if(messageType === "success") {
        return <div className={`p-2 m-2 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800`} role="alert">
        <span className="font-medium">{messageTitle}!</span> {message}
      </div>
    } else {
        return <div className={`p-2 m-2 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800`} role="alert">
        <span className="font-medium">{messageTitle}!</span> {message}
      </div>
    }
}

export default Message;
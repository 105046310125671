import { useState } from 'react';
import { constructURLForRoute, LOGOUT_URL } from '../../urls';

export default function useStorage() {
  const getUser = () => {
    const userString = sessionStorage.getItem('user');
    const user = JSON.parse(userString) || {};
    return user;
  };

  const logOutUser = async (token) => {
    const user = getUser();
    const f = await fetch(constructURLForRoute(LOGOUT_URL), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ refreshToken: user.tokens.refresh.token })
    });
    const req = f.json();
    return req;
  };

  const [user, setUser] = useState(getUser());

  const saveUser = (user) => {
    sessionStorage.setItem('user', JSON.stringify(user));
    setUser(user);
  };

  return {
    setUser: saveUser,
    logOutUser,
    user
  };
}

// URLs for network specific routes
export const FETCH_STAKING_URL = '/staking/fetch';
export const SYNC_TRANSACTIONS_URL = '/transactions/sync';
export const FETCH_VESTING_URL = '/vesting/fetch';
export const SYNC_VESTING_URL = '/vesting/sync';
export const FETCH_ASSETS_URL = '/transactions/portfolio';
export const SYNC_ASSETS_URL = '/transactions/syncportfolio';
export const HIDE_ASSET_URL = '/transactions/hideit';
export const FETCH_PORTFOLIO_URL = '/dashboard/portfolio';
export const FETCH_MAGIC_TOKENS_URL = '/magic/fetchidotokens';
export const FETCH_MAGIC_IDO_URL = '/magic/fetchido';

export const FETCH_MAGIC_IDO_READYTOCLAIM_URL = '/magic/fetchidoreadytoclaim';
export const SYNC_MAGIC_URL = '/magic/sync';
// URLs for general routes
export const LOGOUT_URL = '/auth/logout';
export const LOGIN_URL = '/auth/login';
export const VALIDATE_USER = '/users/validate-user';
export const REGISTER_URL = '/auth/register';
export const FORGOT_PASSWORD_URL = '/auth/forgot-password';
export const CHANGE_PASSWORD_URL = '/auth/change-password';

// constructURLForNetwork returns the URL to access Coin Vesting API with the
// specified chain network and URL route.
export const constructURLForNetwork = (network, route) => {
  // eslint-disable-next-line no-undef
  return `${process.env.REACT_APP_API_URL}/v1/${network}${route}`;
};

// constructURLForRoute returns the URL to access Coin Vesting API with the
// specified URL route.
export const constructURLForRoute = (route) => {
  // eslint-disable-next-line no-undef
  return `${process.env.REACT_APP_API_URL}/v1${route}`;
};
